import ErrorComponent from 'components/ErrorComponent';
import Input from 'components/Input';
import React, { ChangeEvent, Dispatch, FC, memo, ReactNode, useCallback } from 'react';
import Attribute from 'types/enrollments/Attribute';
import { DefaultEnrollmentContact } from 'types/enrollments/Contact';
import { DefaultEnrollmentDoctor } from 'types/enrollments/Doctor';
import EnrollmentContact from 'types/enrollments/EnrollmentContact';
import EnrollmentDoctor from 'types/enrollments/EnrollmentDoctor';
import EnrollmentError from 'types/enrollments/EnrollmentError';
import EnrollmentPractice from 'types/enrollments/EnrollmentPractice';
import EnrollmentResourceNames from 'types/enrollments/EnrollmentResourceNames';
import FieldsReducerAction from 'types/enrollments/FieldsReducerAction';
import { DefaultEnrollmentPractice } from 'types/enrollments/Practice';
import nestedResourceId from 'utils/enrollments/nestedResourceId';
import nestedResourceName from 'utils/enrollments/nestedResourceName';

type EnrollmentFormInputProps = {
  resourceName: keyof typeof EnrollmentResourceNames;
  label: string;
  index: number;
  attribute: Attribute;
  type?: string;
  hidden?: boolean;
  name?: string;
  id?: string;
  resource: (
    | (EnrollmentPractice | DefaultEnrollmentPractice)
    | (EnrollmentDoctor | DefaultEnrollmentDoctor)
    | (EnrollmentContact | DefaultEnrollmentContact)
  );
  resourceDispatch: Dispatch<FieldsReducerAction>;
  errors: EnrollmentError[],
  children?: ReactNode,
};

const EnrollmentFormInput: FC<EnrollmentFormInputProps> = ({
  resourceName,
  label,
  index,
  attribute,
  type,
  hidden,
  name,
  id,
  resource,
  resourceDispatch,
  errors,
  children
}) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    resourceDispatch({
      resourceName,
      type: 'change',
      event,
      attribute,
      index
    });
  }, [resourceName, label, index, attribute, type]);

  return (
    <>
      <Input
        label={label}
        id={id || nestedResourceId(resourceName, index, attribute)}
        name={name || nestedResourceName(resourceName, index, attribute)}
        value={String(resource[attribute] || '')}
        className='form-control'
        onChange={onChange}
        type={type}
        hidden={hidden}
      />
      {children && children}
      {errors && errors[attribute] && (
        <ErrorComponent>
          {errors[attribute].join(', ')}
        </ErrorComponent>
      )}
    </>
  );
};

export default memo(EnrollmentFormInput);
