import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import React, {ChangeEvent, useState, FC, useEffect} from 'react';
import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import CaseFormInput from './CaseFormInput';
import Select2 from 'components/Select2/Select2';
import InsuranceTypes from 'types/InsuranceTypes';

import Insurance from 'types/Insurance';
import Patient from 'types/Patient';
import Card from 'components/Bootstrap/Card';
import railsNestedResource from '../../../utils/railsNestedResources';
import Relations from '../../../types/Relations';
import removeNullFromObject from 'hooks/faxes_ins/removeNullFromObject';
import {isRequiredValidation} from 'utils/validations';
import RefPayer from "types/RefPayer";

type InsuranceFormProps =
  & ResourceForm
  & {
    patient?: Patient;
    attr_hash?: any;
  };
const DEFAULT_INSURANCE = {
  insurance_name: '',
  global_insurance_id: 0,
  insurance_type: '',
  group_number: '',
  insurance_number: '',
  member_id: '',
  policy: '',
  bin: '',
  pcn: '',
  rx_group: '',
  relation_to_patient: Relations.self,
  ref_payer_id: '',
  relation_firstname: '',
  relation_middlename: '',
  relation_lastname: '',
  is_primary_insurance: true,
  _destroy: false
};

const InsuranceForm: FC<InsuranceFormProps> =
  ({ patient = {} as Patient, onChange, visible, attr_hash }) => {
    const typeProps = visible ? {} : { type: 'hidden' };
    const [insuranceType, setInsuranceType] = useState<keyof typeof InsuranceTypes>();

    const [refPayer, setRefPayer] = useState([]);
    const [selectedRefPayer,setSelectedRefPayer] = useState([]);

    const [insurances, setInsurances] = useState<Insurance[]>([]);
    const [relation, setRelation] = useState<keyof typeof Relations>('self');

    const insuranceInputProps = {
      resource: patient,
      nestedResourceName: 'global_insurances',
      onChange,
      attr_hash: attr_hash.insurance,
      ...typeProps
    };

    const handleInsuranceChange = (e, attribute, index) => {
      const requiredValue = e.target.value;
      setInsurances(insurances.map((obj, insuranceIndex) => {
        if (insuranceIndex === index) {
          return ({
            ...obj,
            [attribute]: requiredValue
          });
        } else {
          return obj;
        }
      }));
      onChange(e,attribute);
    };

    useEffect(() => {
      if (insurances.length === 0) {
        handleAddInsurance();
      }
    }, [insurances]);

    useEffect(() => {
      if (patient.global_insurances){
        removeNullFromObject(patient.global_insurances);
        setInsurances(patient.global_insurances);
      }
    },[patient.global_insurances]);


    useEffect(() => {
      const fetchRefPayers = async () => {
        const resp = await fetch('/ref_payers.json');
        if (resp.ok) setRefPayer(await resp.json());
      };

      fetchRefPayers();
    }, []);

    const handleAddInsurance = () => {
      setInsurances(insurances.concat([ DEFAULT_INSURANCE ]));
    };

    const handleRefPayerClick = (value, index) => {
      handleInsuranceChange({ target: { value }},'ref_payer_id',index);
    };

    const insuranceInputAttribute =
      (attribute: '_destroy' | 'is_primary_insurance' | 'ref_payer_id' | 'policy' | 'relation_to_patient' | 'insurance_name' | 'global_insurance_id' | 'insurance_type' | 'group_number' | 'insurance_number' | 'member_id' |'bin' | 'pcn' | 'rx_group' | 'relation_firstname' | 'relation_middlename' | 'relation_lastname' , index: number) => {
        const { name, id } = railsNestedResource('patient_attributes', 'insurances', index,attribute);
        return {
          name: name.replace(/patient_attributes\[/, '[patient_attributes]['),
          id
        };
      };

    const handleRemoveInsurance = (index: number) => {
      // if (insurances[index].global_insurance_id !== 0){
      //   handleInsuranceChange({ target: { value: true }},'_destroy',index);
      // } else {
      setInsurances(insurances.filter((insurance, insuranceIndex) => {
        return insuranceIndex !== index;
      }));
      // }
    };

    const refPayerFromID = (refPayerId: string) => {
      const refPayerObj = refPayer.find(obj => obj.id === refPayerId );
      return refPayerObj?.name;
    };

    const toggleRelation = (value,index) => {
      handleInsuranceChange({ target: { value }},'relation_to_patient',index);
    };

    const handleInsuranceTypeOnChange = (insurance_type:string, index) => {
      const event = {
          target: { value: insurance_type }
        } as ChangeEvent<HTMLInputElement>;
      handleInsuranceChange(event,'insurance_type',index);
    };

    return (
      <div
        style={visible ? {} : { display: 'none' }}
      >
        {
          insurances.map((insurance, index) => {
            return (
              <div
                key={insurance + String(index)}
                style={!insurance._destroy ? { } : { display: 'none' }}
              >
                <Card
                  mb={2}
                  mx={2}
                >
                  <Card.Header clearfix>Insurance</Card.Header>
                  <Card.Body p={2}>
                    <Row>
                      <Col sm={12} >
                        <CaseFormInput
                          {...insuranceInputProps}
                          value={String(insurance.id)  === 'undefined' ? '' : String(insurance.id)}
                          type='hidden'
                          onChange={(e) => {
                            handleInsuranceChange(e, 'global_insurance_id', index);
                          }}
                          label='123'
                          attribute={insuranceInputAttribute('global_insurance_id', index).name}
                          id={insuranceInputAttribute('global_insurance_id', index).id}
                          deeplyNested
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <CaseFormInput
                          {...insuranceInputProps}
                          value={insurance.insurance_name}
                          onChange={(e) => {
                            handleInsuranceChange(e, 'insurance_name', index);
                          }}
                          label='Insurance Name'
                          required={isRequiredValidation('insurance','insurance_name',attr_hash)}
                          attribute={insuranceInputAttribute('insurance_name', index).name}
                          id={insuranceInputAttribute('insurance_name', index).id}
                          deeplyNested
                        />
                      </Col>
                      <Col sm={6}>
                        <CaseFormInput
                          {...insuranceInputProps}
                          value={insurance.insurance_type}
                          hidden={true}
                          label='Insurance type'
                          attribute={insuranceInputAttribute('insurance_type', index).name}
                          id={insuranceInputAttribute('insurance_type', index).id}
                          deeplyNested
                        />
                        <Select2
                            value={insuranceType === undefined ? InsuranceTypes[insurance.insurance_type] : insuranceType}
                            placeholder='Select Insurance Type...'
                          >
                            {
                              (Object.keys(InsuranceTypes) as (keyof typeof InsuranceTypes)[])
                                .map((curr: keyof typeof InsuranceTypes) => {
                                  return (
                                    <Select2.Option
                                      key={curr}
                                      onClick={() => { handleInsuranceTypeOnChange(curr, index); }}
                                    >
                                      {InsuranceTypes[curr]}
                                    </Select2.Option>
                                  );
                                })
                            }
                          </Select2>

                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <CaseFormInput
                          {...insuranceInputProps}
                          type='select'
                          hidden={!visible}
                          label='Relation To Patient'
                          value={insurance.relation_to_patient}
                          required={isRequiredValidation('insurance','relation_to_patient',attr_hash)}
                          attribute={insuranceInputAttribute('relation_to_patient', index).name}
                          id={insuranceInputAttribute('relation_to_patient', index).id}
                          deeplyNested
                        >
                          <Select2
                            value={Relations[insurance.relation_to_patient]}
                            placeholder='Select...'
                          >
                            {
                              (
                                Object.keys(Relations) as (
                                  keyof typeof Relations)[]
                              )
                                .map((curr: keyof typeof Relations) => {
                                  return (
                                    <Select2.Option
                                      key={curr}
                                      onClick={() => {
                                        toggleRelation(curr,index);
                                      }}
                                    >
                                      {Relations[curr]}
                                    </Select2.Option>
                                  );
                                })
                            }
                          </Select2>
                        </CaseFormInput>
                      </Col>

                      { insurance.relation_to_patient !== Relations.self && (
                        <Row>
                          <Col sm={4}>
                            <CaseFormInput
                              {...insuranceInputProps}
                              value={insurance.relation_firstname}
                              onChange={(e) => {
                                handleInsuranceChange(e, 'relation_firstname', index);
                              }}
                              label='First Name'
                              attribute={insuranceInputAttribute('relation_firstname', index).name}
                              id={insuranceInputAttribute('relation_firstname', index).id}
                              deeplyNested
                            />
                          </Col>
                          <Col sm={4}>
                            <CaseFormInput
                              {...insuranceInputProps}
                              value={insurance.relation_middlename}
                              onChange={(e) => {
                                handleInsuranceChange(e, 'relation_middlename', index);
                              }}
                              label='Middle Name'
                              attribute={insuranceInputAttribute('relation_middlename', index).name}
                              id={insuranceInputAttribute('relation_middlename', index).id}
                              deeplyNested
                            />
                          </Col>
                          <Col sm={4}>
                            <CaseFormInput
                              {...insuranceInputProps}
                              value={insurance.relation_lastname}
                              onChange={(e) => {
                                handleInsuranceChange(e, 'relation_lastname', index);
                              }}
                              label='Last Name'
                              attribute={insuranceInputAttribute('relation_lastname', index).name}
                              id={insuranceInputAttribute('relation_lastname', index).id}
                              deeplyNested
                            />
                          </Col>
                        </Row>
                      )}
                    </Row>
                    <Row>
                      <CaseFormInput
                        {...insuranceInputProps}
                        label='Ref Payers'
                        type='hidden'
                        value={insurance.ref_payer_id}
                        attribute={insuranceInputAttribute('ref_payer_id', index).name}
                        id={insuranceInputAttribute('ref_payer_id', index).id}
                        deeplyNested
                      />
                      <Col sm={6}>
                        <label>Ref Payers</label>
                        <Select2
                          value={refPayerFromID(insurance.ref_payer_id)}
                          placeholder='Select a RefPayer...'
                        >
                          {
                            refPayer.map((refPayer: RefPayer) => {
                              return(
                                <Select2.Option
                                  key={refPayer?.id}
                                  onClick={() => {handleRefPayerClick(refPayer?.id, index);}}>
                                  {refPayer?.name}
                                </Select2.Option>
                              );
                            })
                          }
                        </Select2>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <Card mt={2} mb={2} mx={2}>
                          <Card.Header clearfix>Medical Insurance</Card.Header>
                          <Card.Body p={2}>
                            <Row>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.policy}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'policy', index);
                                  }}
                                  label='Member ID'
                                  required={isRequiredValidation('insurance','policy',attr_hash)}
                                  attribute={insuranceInputAttribute('policy', index).name}
                                  id={insuranceInputAttribute('policy', index).id}
                                  deeplyNested
                                />
                              </Col>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.group_number}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'group_number', index);
                                  }}
                                  label='Group'
                                  attribute={insuranceInputAttribute('group_number', index).name}
                                  id={insuranceInputAttribute('group_number', index).id}
                                  deeplyNested
                                />
                              </Col>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.insurance_number}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'insurance_number', index);
                                  }}
                                  label='Phone'
                                  attribute={insuranceInputAttribute('insurance_number', index).name}
                                  id={insuranceInputAttribute('insurance_number', index).id}
                                  deeplyNested
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Card mb={2} mx={2}>
                          <Card.Header clearfix>Pharmacy Insurance</Card.Header>
                          <Card.Body p={2}>
                            <Row>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.member_id}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'member_id', index);
                                  }}
                                  label='Rx Member ID'
                                  required={isRequiredValidation('insurance','member_id',attr_hash)}
                                  attribute={insuranceInputAttribute('member_id', index).name}
                                  id={insuranceInputAttribute('member_id', index).id}
                                  deeplyNested
                                />
                              </Col>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.bin}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'bin', index);
                                  }}
                                  label='BIN'
                                  attribute={insuranceInputAttribute('bin', index).name}
                                  id={insuranceInputAttribute('bin', index).id}
                                  deeplyNested
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.pcn}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'pcn', index);
                                  }}
                                  label='PCN'
                                  attribute={insuranceInputAttribute('pcn', index).name}
                                  id={insuranceInputAttribute('pcn', index).id}
                                  deeplyNested
                                />
                              </Col>
                              <Col sm={6}>
                                <CaseFormInput
                                  {...insuranceInputProps}
                                  value={insurance.rx_group}
                                  onChange={(e) => {
                                    handleInsuranceChange(e, 'rx_group', index);
                                  }}
                                  label='RX GROUP'
                                  attribute={insuranceInputAttribute('rx_group', index).name}
                                  id={insuranceInputAttribute('rx_group', index).id}
                                  deeplyNested
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <CaseFormInput
                        {...insuranceInputProps}
                        label='Is Primary'
                        id={insuranceInputAttribute('is_primary_insurance', index).id}
                        attribute={insuranceInputAttribute('is_primary_insurance', index).name}
                        type='checkbox'
                        onChange={(e) => {
                          handleInsuranceChange({
                            ...e,
                            target: {
                              ...e.target,
                              value: e.target.checked
                            }
                          } as unknown as ChangeEvent<HTMLInputElement>, 'is_primary_insurance', index);
                        }}
                        value={insurance.is_primary_insurance}
                        deeplyNested
                      />
                    </Row>
                    <Row>
                      <CaseFormInput
                        {...insuranceInputProps}
                        value={String(insurance._destroy) === 'undefined' ? '' : String(insurance._destroy)}
                        type='hidden'
                        label='destroy'
                        attribute={insuranceInputAttribute('_destroy', index).name}
                        id={insuranceInputAttribute('_destroy', index).id}
                        deeplyNested
                      />
                      <div
                        className="text-right"
                        style={visible ? { } : { display: 'none' }}
                      >
                        <label></label>
                        <a
                          className='add_fields btn btn-danger my-2'
                          onClick={() => { handleRemoveInsurance(index); }}
                          style={{ display: 'block', color: 'white' }}
                        >
                          Remove Insurance
                        </a>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            );
          })
        }
        <Row>
          <Col md={12}>
            <div
              className="text-right"
              style={visible ? {} : { display: 'none' }}
            >
              <a
                className='add_fields btn btn-success my-2'
                onClick={handleAddInsurance}
                style={{ color: 'white' }}
              >
                Add Insurance
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

export default InsuranceForm;
