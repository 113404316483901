import Card from 'components/Bootstrap/Card';
import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import Select2 from 'components/Select2/Select2';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import Prescription from 'types/faxes_ins_cases/Prescription';
import Product from 'types/Product';
import ShipTos from 'types/ShipTos';
import railsNestedResource from 'utils/railsNestedResources';
import CaseFormInput from './CaseFormInput';
import PracticeType from 'types/PracticeType'
import PrescriptionAttributeForm from 'components/faxes_ins_cases/New/PrescriptionAttributeForm';

type PrescriptionFormProps = Omit<ResourceForm, 'visible'> & {
  prescription: Prescription;
  attr_hash?: any;
};
const DEFAULT_SHIPMENT_ATTRIBUTES = {
  ship_to_street: '',
  ship_to_suite: '',
  ship_to_city: '',
  ship_to_state: '',
  ship_to_zip: '',
  phone: '',
  fax: '',
  email: '',
  practice_name: '',
  practice_type: PracticeType.office.toLowerCase(),
  ship_to: ShipTos.provider.toLowerCase()
};

type ShipToAttributes = {
  ship_to_street: string,
  ship_to_suite: string,
  ship_to_city: string,
  ship_to_state: string,
  ship_to_zip: string,
  phone: string,
  fax: string,
  email: string,
  practice_name: string,
  practice_type: keyof typeof PracticeType,
  ship_to: keyof typeof ShipTos
};


const PrescriptionForm: FC<PrescriptionFormProps> = ({ prescription = {} as Prescription, onChange, attr_hash}) => {
  const [products, setProducts] = useState([]);
  const [shipToAttributes, setShipToAttributes] = useState<ShipToAttributes>(
    DEFAULT_SHIPMENT_ATTRIBUTES as ShipToAttributes
  );
  const inputProps = {
    resource: prescription,
    onChange: onChange,
    nestedResourceName: 'prescription',
  };

    const disabledInputProps ={
      disabled: true,
      ...inputProps
    };

    const handleProductClick = (product: Product) => {
      const event = {
        target: { value: product?.id }
      };

      onChange(event as unknown as ChangeEvent<HTMLInputElement>, 'drug_id');
    };

    const handleShipToOnClick = (shipTo: keyof typeof ShipTos) => {
      const event = {
        target: { value: shipTo }
      };
      handleShipToAttributesOnChange(event as unknown as ChangeEvent<HTMLInputElement>,'ship_to');
    };
     const handlePracticeTypeOnClick = (practiceType: keyof typeof PracticeType) => {
      const event = {
        target: { value: practiceType }
      };
      handleShipToAttributesOnChange(event as unknown as ChangeEvent<HTMLInputElement>,'practice_type');
    };

    const productName = (prodID: number) => {
      const product = products.find(obj => obj.id === prodID );
      return product?.name;

    };


    useEffect(() => {
      const fetchProducts = async () => {
        const resp = await fetch('/drugs.json');

        if (resp.ok) setProducts(await resp.json());
      };

      fetchProducts();
    }, []);

    const handleShipToAttributesOnChange =
      (e: ChangeEvent<HTMLInputElement>, attribute: keyof ShipToAttributes) => {
        setShipToAttributes({
          ...shipToAttributes,
          [attribute]: e.target.value
        });
      };

    const shipToInputAttribute = (attribute: keyof ShipToAttributes) => {
      const { name, id } = railsNestedResource(
        'prescription_attributes',
        'shipments',
        0,
        attribute
      );
      return {
        name: name.replace(
          /prescription_attributes\[/,
          '[prescription_attributes]['
        ),
        id
      };
    };


    return (
      <Col md={12}>
        <Card mb={2} mx={2}>
          <Card.Header clearfix>Prescription</Card.Header>
          <Card.Body p={2}>
            <Row>
              <Col>
                <CaseFormInput
                  label="Product"
                  attribute="drug_id"
                  type="hidden"
                  {...inputProps}
                />
                <label>Product</label>
                <Select2
                  value={productName(prescription?.drug_id)}
                  placeholder="Select a Product..."

                >
                  {products.map((prod: Product) => {
                    return (
                      <Select2.Option
                        key={prod?.id}
                        resource={prod}
                        onClick={() => {
                          handleProductClick(prod);
                        }}
                      >
                        {prod?.name}
                      </Select2.Option>
                    );
                  })}
                </Select2>
              </Col>

            </Row>
            {prescription?.drug_id && (
              <PrescriptionAttributeForm
                attr_hash = {attr_hash}
                onChange={onChange}

                prescription={prescription}
                visible={Boolean(prescription?.drug_id)}
              />
            )}
            <Row>
              <Col sm={3}>
                <CaseFormInput label="Qty" attribute="qty" {...inputProps} />
              </Col>
              <Col sm={3}>
                <CaseFormInput
                  label="Refills"
                  placeholder="0"
                  attribute="refills_authorized"
                  {...inputProps}
                />
              </Col>
              <Col sm={6}>
                <CaseFormInput
                  {...inputProps}
                  type="hidden"
                  label="Street"
                  value={shipToAttributes.ship_to}
                  onChange={handleShipToAttributesOnChange}
                  attribute={shipToInputAttribute('ship_to').name}
                  id={shipToInputAttribute('ship_to').id}
                  deeplyNested
                />
                <label>Ship To</label>
                <Select2
                  value={ShipTos[shipToAttributes.ship_to]}
                  placeholder="Select a Ship To..."
                >
                  {(Object.keys(ShipTos) as (keyof typeof ShipTos)[]).map(
                    (shipTo: keyof typeof ShipTos) => {
                      return (
                        <Select2.Option
                          key={shipTo}
                          onClick={() => {
                            handleShipToOnClick(shipTo);
                          }}
                        >
                          {ShipTos[shipTo]}
                        </Select2.Option>
                      );
                    }
                  )}
                </Select2>
              </Col>
            </Row>
            {ShipTos[shipToAttributes.ship_to] === ShipTos.other && (
              <>
              <Row>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="Practice Name"
                    value={shipToAttributes.practice_name}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'practice_name');
                    }}
                    attribute={shipToInputAttribute('practice_name').name}
                    id={shipToInputAttribute('practice_name').id}
                    deeplyNested
                  />
                </Col>
                  <CaseFormInput
                    {...inputProps}
                    label="Practice Type"
                    type={'hidden'}
                    value={shipToAttributes.practice_type}
                    onChange={ handleShipToAttributesOnChange}
                    attribute={shipToInputAttribute('practice_type').name}
                    id={shipToInputAttribute('practice_type').id}
                    deeplyNested
                  />
                <Col sm={3}>
                <label>Practice Type</label>
                <Select2
                  value={PracticeType[shipToAttributes.practice_type]}
                  placeholder="Select a Practice Type..."
                >
                  {(Object.keys(PracticeType) as (keyof typeof PracticeType)[]).map(
                    (practice_type: keyof typeof PracticeType) => {
                      return (
                        <Select2.Option
                          key={practice_type}
                          onClick={() => {
                            handlePracticeTypeOnClick(practice_type);
                          }}
                        >
                          {PracticeType[practice_type]}
                        </Select2.Option>
                      );
                    }
                  )}
                </Select2>
              </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="Phone"
                    value={shipToAttributes.phone}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'phone');
                    }}
                    attribute={shipToInputAttribute('phone').name}
                    id={shipToInputAttribute('phone').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="Fax"
                    value={shipToAttributes.fax}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'fax');
                    }}
                    attribute={shipToInputAttribute('fax').name}
                    id={shipToInputAttribute('fax').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={3}>
                  <CaseFormInput
                    {...inputProps}
                    label="Email"
                    value={shipToAttributes.email}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'email');
                    }}
                    attribute={shipToInputAttribute('email').name}
                    id={shipToInputAttribute('email').id}
                    deeplyNested
                  />
                </Col>

              </Row>
              <Row>
                <Col sm={4}>
                  <CaseFormInput
                    {...inputProps}
                    label="Street"
                    value={shipToAttributes.ship_to_street}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'ship_to_street');
                    }}
                    attribute={shipToInputAttribute('ship_to_street').name}
                    id={shipToInputAttribute('ship_to_street').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="Suite"
                    value={shipToAttributes.ship_to_suite}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'ship_to_suite');
                    }}
                    attribute={shipToInputAttribute('ship_to_suite').name}
                    id={shipToInputAttribute('ship_to_suite').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="City"
                    value={shipToAttributes.ship_to_city}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'ship_to_city');
                    }}
                    attribute={shipToInputAttribute('ship_to_city').name}
                    id={shipToInputAttribute('ship_to_city').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="State"
                    value={shipToAttributes.ship_to_state}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'ship_to_state');
                    }}
                    attribute={shipToInputAttribute('ship_to_state').name}
                    id={shipToInputAttribute('ship_to_state').id}
                    deeplyNested
                  />
                </Col>
                <Col sm={2}>
                  <CaseFormInput
                    {...inputProps}
                    label="Zip"
                    value={shipToAttributes.ship_to_zip}
                    onChange={(e) => {
                      handleShipToAttributesOnChange(e, 'ship_to_zip');
                    }}
                    attribute={shipToInputAttribute('ship_to_zip').name}
                    id={shipToInputAttribute('ship_to_zip').id}
                    deeplyNested
                  />
                </Col>
              </Row>
              </>
            )}
            <Row>

              <Col sm={3}>
                <CaseFormInput
                  label="Is DAW"
                  attribute="is_daw"
                  type="checkbox"
                  {...disabledInputProps}
                />
              </Col>
              <Col sm={3}>
                <CaseFormInput
                  label="Transferable"
                  attribute="transferable"
                  type="checkbox"
                  {...inputProps}
                />
              </Col>
              <Col sm={3}>
                <CaseFormInput
                  label="Auto Transfer"
                  attribute="is_specialty_pharmacy_only"
                  type="checkbox"
                  {...inputProps}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <CaseFormInput
                  label="Direction"
                  attribute="direction"
                  {...inputProps}
                />
              </Col>
              <Col sm={6}>
                <CaseFormInput label="Note" attribute="note" {...inputProps} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  };

export default PrescriptionForm;
